import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const Energy = ({ data }) => {
  return (
    <>
      <Seo
        title="Energy Industry in North Central Pennsylvania"
        description="The Energy (ENGY) cluster includes industries associated with the production and distribution of energy, as well as the waste disposal industries associated with the remediation and environmental cleanup related to energy production"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="70%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Energy</h2>

            <p className="title-small">
              The Energy (ENGY) cluster includes industries associated with the
              production and distribution of energy, as well as the waste
              disposal industries associated with the remediation and
              environmental cleanup related to energy production.
            </p>
          </div>

          <div className="intro-text" style={{ alignSelf: "center" }}>
            <p>
              In the cluster you will find a wide variety of industries, from
              petroleum refineries to battery manufacturing to generator
              manufacturing.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro industry="Energy" image={data.pathwaysImage.sharp.image} />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: energy {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(
      relativePath: { eq: "ehmitrich-fW6lwDM26o0-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "science-in-hd-8S2RmC-POCU-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "matthew-henry-yETqkLnhsUI-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "matthew-henry-yETqkLnhsUI-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;
export default Energy;
